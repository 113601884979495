import React from 'react';
import Button from "../components/materialComponents/CustomButtons/Button.js";
import BusinessLocation from '../components/BusinessLocation.js';
import { FiShoppingCart, FiChevronLeft } from 'react-icons/fi';

let BusinessLocationPage = props => {
  const { 
    booking_data,
    accountPage,
    nextPage,
    previousPage,
    onSubmit,
    business_settings,
    business_location_data,
    business_location_id,
    service_location_map_data,
    business_img,
    linked_service_id
  } = props;
  let businessLocationData = business_location_data;
  if(linked_service_id) {
    const businessLocationIds = service_location_map_data.filter(x => x.service_business_id === linked_service_id).map(x => x.business_location_id);
    businessLocationData = businessLocationData.filter(x => businessLocationIds.includes(x.business_location_id));
    if(businessLocationData?.length === 1) {
      onSubmit(businessLocationData[0].business_location_id);
      return;
    }
  }
  return (
    <div className="left">
      <div className="page-title" style={{backgroundColor: business_settings?.service_header_background, color: business_settings?.service_header_title}}>
        <div style={{ display: 'flex' }}>
          <div onClick={previousPage} style={{ cursor: 'pointer', marginRight: 10 }}>
            <FiChevronLeft size={20} style={{ marginBottom: -3 }} color={business_settings?.service_header_title}/>
          </div>
          <div>
            Locations
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <Button size="sm" onClick={accountPage}>
            Account
          </Button>
          <Button id="cartToggleBtn" className="book-button-header" size="sm" onClick={() => { toggleShopCart() }}>
            <FiShoppingCart /> {booking_data ? booking_data.length : 0}
          </Button>
        </div>
      </div>
      <div>
        {businessLocationData && businessLocationData.map((businessLocationObj,) => {
          return (
            <BusinessLocation
              data={businessLocationObj}
              businessImg={business_img}
              selected={businessLocationObj.business_location_id === business_location_id}
              handleClick={onSubmit}
            />
          );
        })}
      </div>
    </div>
  );


};


export default BusinessLocationPage;