const accordionStyle = {
    accordionContainer: {
        borderRadius: '0!important',
        boxShadow: 'none',
        margin: '0!important'
    },
    accordionHeader: {
        padding: '16px 23px',
        minHeight: 'unset!important',
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        '& .MuiAccordionSummary-content': {
            margin: '0!important'
        }
    },
    accordionTitle: {
        fontWeight: '500'
    },
    accordionDescription: {
        fontSize: 11,
        color: '#707070'
    },
    accordionContent: {
        flexDirection: 'column',
        padding: '16px 13px',
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        boxShadow: 'inset 0px 11px 8px -10px #bbbbbb, inset 0px -11px 8px -10px #bbbbbb',
        backgroundColor: '#f9f9f9'
    }
}

export default accordionStyle;