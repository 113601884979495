import React from 'react';

export const Cart = (servicesLength, total, total_poa, currency, deposit) => {
  if(servicesLength > 0) {
    return (
      <div>
        <div className="total-container">
          <div className="total-title">Total</div>
          <div className="total-price">
            <span className="price">
              {total_poa ? 'POA + ' : null}
              {total > 0 ? currency + Number(total / 100).toFixed(2) : 'FREE'}
            </span>
          </div>
        </div>
        {deposit > 0 ?
          <div className="deposit">
            <div className="total-info">
              <div className="total-title" style={{textAlign: 'left'}}>Deposit</div>
              <div className="total-price"><span className="price">{currency}{Number(deposit / 100).toFixed(2)}</span></div>
            </div>
          </div>
        : null}
      </div>
    );
  } else {
    return (
      <div className="cart-empty">
        No services selected
      </div>
    );
  }
}