import { initializeApp, getApps } from "firebase/app";

var config = {
  apiKey: "AIzaSyCKzk4F2IzfJc5HnL6mvN9GfQRCq5bmmpM",
  authDomain: "proxy.styler.digital",
};

try {
  if (!getApps().length) {
    initializeApp(config);
  }
  
} catch (e) {
  console.log(e);
}
