import { stylerBaseUrl, wsBaseUrl, devBaseUrl } from './../utils/constants';
import axios from 'axios';

let baseUrl;
switch(window?.location.hostname) {
    case "booking.whatstyle.uk":
        baseUrl = wsBaseUrl;
        break;
    case "booking.styler.digital":
        baseUrl = stylerBaseUrl;
        break;
    case "localhost":
        baseUrl = devBaseUrl;
        break;
    default:
        console.log('Unrecognised host - unable to set API base URL');
}

const remoteApi = {
    getBusinessServiceCategories(businessId) { 
        return axios.post(`${baseUrl}/widget/business_service_categories`, { businessId });
    },
    getBusinessServices(businessId) {
        return axios.post(`${baseUrl}/widget/business_services_v2`, { businessId });
    },
    getBusinessStaff(businessId) {
        return axios.post(`${baseUrl}/widget/business_staff`, { businessId });
    },
    getBusinessSettings(businessId) {
        return axios.post(`${baseUrl}/widget/business_settings`, { businessId });
    },
    getBusinessDetails(businessSlug) {
        return axios.post(`${baseUrl}/widget/business_details`, { businessSlug });
    },
    getBusinessLocations(businessId) {
        return axios.get(`${baseUrl}/widget/business_location/${businessId}`);
    },
    resetPassword(body) {
        return axios.post(`${baseUrl}/widget/reset_password`, body);
    },
    verifyEmail() {
        return axios.get(`${baseUrl}/widget/verify_email`, { withCredentials: true });
    },
    getUser() {
        return axios.get(`${baseUrl}/widget/get_user`, { withCredentials: true });
    },
    signIn(body) {
        return axios.post(`${baseUrl}/widget/signin`, body, { withCredentials: true });
    },
    signOut() {
        return axios.get(`${baseUrl}/widget/signout`, { withCredentials: true });
    },
    socialSignIn(body) {
        return axios.post(`${baseUrl}/widget/socialSignin`, body, { withCredentials: true });
    },
    signUp(body) {
        return axios.post(`${baseUrl}/widget/signup`, body, { withCredentials: true });
    },
    getStripeCustomer: () => {
        return axios.get(`${baseUrl}/widget/get_stripe_customer`, { withCredentials: true });
    },
    setDefaultPaymentMethod(body) {
        return axios.post(`${baseUrl}/widget/set_default_payment_method`, body, { withCredentials: true });
    },
    addPaymentMethod(body) {
        return axios.post(`${baseUrl}/widget/add_payment_method`, body, { withCredentials: true });
    },
    deletePaymentMethod(body) {
        return axios.post(`${baseUrl}/widget/delete_payment_method`, body, { withCredentials: true });
    },
    createPaymentIntent(body) {
        return axios.post(`${baseUrl}/widget/create_payment_intent`, body, { withCredentials: true });
    },
    newBooking(body) {
        return axios.post(`${baseUrl}/widget/new_booking`, body, { withCredentials: true });
    },
    cancelBooking(body) {
        return axios.post(`${baseUrl}/widget/cancel_booking`, body, { withCredentials: true });
    },
    getBookings(body) {
        return axios.post(`${baseUrl}/widget/get_bookings`, body, { withCredentials: true });
    },
    updateUser(body) {
        return axios.post(`${baseUrl}/widget/update_user`, body, { withCredentials: true });
    },
    getBusinessHours(businessId) {
        return axios.get(`${baseUrl}/widget/business_hours/${businessId}`, { withCredentials: true });
    },
};

export default remoteApi;