import React, { Component } from 'react';
import { FiUser, FiSettings } from 'react-icons/fi';
import Button from "./materialComponents/CustomButtons/Button.js";
import CustomDropdown from "./materialComponents/CustomDropdown/CustomDropdown.js";
import { mediaUrl } from './../utils/constants';

class CustomRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleDropDownClick(param) {
    switch(param) {
      case 'Menu':
        this.props.accountMenu();
        break;
      case 'Sign out':
        this.props.signOut();
        break;
      default:
        break;
    }
  }

  render() {
    var backgroundStyle = {backgroundImage: `url(${mediaUrl + this.props.business_img})`};
    return (
    <div className="right" style={ backgroundStyle }>
        {this.props.componentToRender == 3 || this.props.componentToRender == 4 ? <div className="user-container-sm">
            <div className="user-avatar-container">
            <div className="user-avatar">
                <FiUser size={'50px'} color={'#ffffff'} />
            </div>
            </div>
            <div className="user-header" style={{paddingBottom: 15}}>
            {this.props.user ? this.props.user.user_firstname + ' ' + this.props.user.user_lastname : null}
            <div style={{paddingTop: 5}}>
              <Button className="book-button" size="sm" disabled={this.props.booking_enabled !== 1} onClick={() => { this.props.bookAgain() }}>
                New booking
              </Button>
              <CustomDropdown
                onClick={(param) => {this.handleDropDownClick(param)}}
                buttonText={<FiSettings color={'#000000'} style={{height: 15, width: 15}} />}
                dropdownList={[
                  "Menu",
                  {divider: true},
                  "Sign out"
                ]}
                hoverColor="black"
                headerDropdown={false}
                dropDownButtonColor="white"
              />
            </div>
            {/*<div style={{fontSize: 12, marginTop: 15, marginBottom: 5}}>Not you?</div>
                <div style={{paddingTop: 0}}>
                    <Button className="book-button" color="white" size="sm" onClick={() => { this.props.signOut() }}>
                    Sign out
                    </Button>
                </div>*/}
            </div>
        </div> : <div style={{height: '100%'}}>{this.props.businessLogo ? <img className="business-logo-side" src={this.props.businessLogo}></img> : null}</div>}
    </div>
    );
  }
}

export default CustomRight;