import React from 'react';
import './Form.css';

import dayjs from 'dayjs';

import {
  FiChevronLeft,
  FiChevronRight,
  FiCheck,
  FiCreditCard,
  FiMapPin
} from 'react-icons/fi';
import {
  FaBarcode,
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex
} from 'react-icons/fa';
import CircularProgress from '@mui/material/CircularProgress';
import Button from "./components/materialComponents/CustomButtons/Button.js";
import { Cart } from './components/Cart';
import { CartService } from './components/CartService';
import { PaymentMethodsModal } from './components/PaymentMethodsModal';
import remoteApi from './api/remoteApi';
import { mediaUrl } from './utils/constants';

import availabilityApi from './api/availabilityApi.js';
import { formatTimeToMinuteInt } from './utils/formatting';

class WizardFormLandingPage5 extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      confirmed: false,
      loading: false,
      bookError: false,
      bookErrorMessage: 'Unable to book. Please contact the business.',
      paymentMethods: [],
      defaultPaymentMethod: null,
      deposit: 0,
      paymentMethodsModalVisible: false,
      stripeCustomerId: null
    };
    this.togglePaymentMethodsModal = this.togglePaymentMethodsModal.bind(this);
    this.submitPaymentMethodChange = this.submitPaymentMethodChange.bind(this);
    this.submitNewPaymentMethod = this.submitNewPaymentMethod.bind(this);
  }

  componentDidMount() {
    if(!this.props.user){
      this.checkLoggedIn();
    } else {
      this.checkBookingTimeNotReserved();
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.user !== this.props.user) {
      this.checkBookingTimeNotReserved();
    }
  }

  async checkBookingTimeNotReserved() {
    try {
      if(!this.props.user){
        return;
      }
      this.setState({ loading: true });
      // Check to see if booking time is not already reserved
      const availabilityBookingEvents = [];
      let startInt = formatTimeToMinuteInt(this.props.time);
      let endInt = startInt;
      for(let bookingObj of this.props.booking_data) {
        endInt += bookingObj.serviceDuration;
        availabilityBookingEvents.push({
          staff_id: bookingObj.staff_id,
          start_int: startInt,
          end_int: endInt
        });
        startInt = endInt;
      }
      const{ data } = await availabilityApi.postAvailability(
        this.props.business_id,
        this.props.business_location_id,
        this.props.date.format('YYYY-MM-DD'),
        this.props.user.user_id,
        availabilityBookingEvents
      );
      if(data?.is_reserved === true) {
        alert('Your selected booking time is no longer available. Please choose again.');
        this.props.previousPage();
        return;
      }
    } catch(e) {
      console.log('Unable to check booking reservation');
    }
    this.getPaymentMethods();
  }

  async checkLoggedIn() {
    try {
      let response = await remoteApi.getUser();
      this.props.setUser(response.data.userData);
    } catch(e) {
      this.props.previousPage();
    }
  }

  async getPaymentMethods() {
    try {
      this.setState({ loading: true });
      let customer = await remoteApi.getStripeCustomer();
      const default_source = customer.data.default_source;
      const default_payment_method = customer.data.sources ? customer.data.sources.find(x => x.id === default_source) : null;
      this.setState({
        stripeCustomerId: customer.data.stripe_customer_id,
        defaultPaymentMethod: default_payment_method,
        paymentMethods: customer.data.sources,
        loading: false
      });
    } catch(e) {
      this.setState({ loading: false });
      console.log('Unable to get payment methods');
    }
  }

  async createBooking(paymentIntentId = null) {
    try {
      await remoteApi.newBooking({
        booking_data: this.props.booking_data,
        business_location_id: this.props.business_location_id,
        business_id: this.props.business_id,
        booking_date: this.props.date.format('YYYY-MM-DD'),
        booking_time: this.props.time,
        payment_intent_id: paymentIntentId
      });
      this.setState({ confirmed: true, loading: false, bookError: false });
    } catch(e) {
      if (e.response.data && e.response.data.message === 'email not verified'){
        this.setState({loading: false, bookError: true, bookErrorMessage: 'Please verify your email address using the verification email which we sent you.' });
      } else if (e.response.data && e.response.data.message === 'booking time unavailable'){
        this.setState({loading: false, bookError: true, bookErrorMessage: 'This booking time is no longer available. Please choose another booking time.' });
      } else if (e.response.data && e.response.data.message === 'user blocked'){
        this.setState({loading: false, bookError: true, bookErrorMessage: 'Your account has been temporarily suspended from making bookings with us.' });
      } else {
        this.setState({ bookError: true, loading: false, bookErrorMessage: 'An error occured during the booking process. Please contact us at support@styler.digital if the problem persists.' });
      }
      return;
    }
  }

  async submitBooking() {
    if(this.state.confirmed || (this.props.booking_data && this.props.booking_data.length === 0)){
      return;
    }
    // Check time reservation isn't expired
    const timeSelectDiff = dayjs().diff(this.props.time_added, 'm');
    if(timeSelectDiff >= 5){
      this.setState({ bookError: true, bookErrorMessage: 'We only reserve your booking time for a period of 5 minutes. Please go back and choose your booking time again.' });
      return;
    }
    // If cancellation fee is enabled, ensure user has valid payment method even when the deposit is zero
    if(this.props.business_stripe_account && this.props.business_stripe_account === true && ((this.props.business_cancellation_fee_enabled &&
      this.props.business_cancellation_fee_enabled === 1) || (this.props.business_no_show_fee_enabled && this.props.business_no_show_fee_enabled === 1)) &&
      !this.state.defaultPaymentMethod && this.state.deposit === 0) {
      this.setState({ bookError: true, bookErrorMessage: "A valid payment method is required on file to book." });
      return;
    }
    this.setState({ loading: true, bookError: false, bookErrorMessage: null});
    if(this.state.deposit > 0){
      if(!this.state.defaultPaymentMethod){
        this.setState({ loading: false, bookError: true, bookErrorMessage: "A valid payment method is required." });
        return;
      }
      // Create payment intent
      try {
        let selectedServices = [], selectedStaff = [];
        this.props.booking_data.map((bookingObj, index) => {
          selectedServices.push(bookingObj.service_business_detail_id);
          selectedStaff.push(bookingObj.staff_id);
        });
        let response;
        try {
          response = await remoteApi.createPaymentIntent({
            services: selectedServices,
            staff: selectedStaff,
            business_id: this.props.business_id,
            business_location_id: this.props.business_location_id,
            booking_date: this.props.date.format('YYYY-MM-DD'),
            booking_time: this.props.time,
            payment_method_id: this.state.defaultPaymentMethod.id
          });
        } catch(e) {
          if(e.response && e.response.data && e.response.data.message){
            let errData = e.response.data;
            if(errData.message === "email not verified"){
                this.setState({ bookError: true, bookErrorMessage: "Please verify your email address using the verification email.", loading: false });
            } else if(errData.message === "booking time unavailable"){
                this.setState({ bookError: true, bookErrorMessage: "This booking time is no longer available. Please choose another booking time.", loading: false });
            } else if(errData.message === "user blocked"){
                this.setState({ bookError: true, bookErrorMessage: "Your account has been temporarily suspended from making bookings with us.", loading: false });
            } else {
                this.setState({ bookError: true, bookErrorMessage: "We're sorry. An unexpected error occured. You have not been charged.", loading: false });
            }
          } else {
              this.setState({ bookError: true, bookErrorMessage: "We're sorry. An unexpected error occured. You have not been charged.", loading: false });
          }
          return;
        }
        let paymentIntentClientSecret = response.data.payment_intent_client_secret;
        let paymentResponse = await this.props.stripe.confirmCardPayment(paymentIntentClientSecret, { payment_method: this.state.defaultPaymentMethod.id });
        if(paymentResponse.error) {
          this.setState({ bookError: true, loading: false, bookErrorMessage: paymentResponse.error.message });
          return;
        }
        this.createBooking(paymentResponse.paymentIntent.id);
      } catch(err) {
        console.log(err);
        this.setState({ bookError: true, loading: false, bookErrorMessage: 'Unable to process booking. Please contact us at support@styler.digital for further assistance.' });
        return;
      }
    } else {
      this.createBooking();
    }
  }

  handlePaymentMethodsClick() {
    if(this.state.confirmed || this.state.loading) {
      return;
    }
    this.togglePaymentMethodsModal();
  }

  renderSelectedCard() {
    let icon;
    if(this.state.defaultPaymentMethod) {
      switch(this.state.defaultPaymentMethod.brand) {
        case 'Visa':
          icon = <FaCcVisa size={16} color={'#1A1F71'}/>;
          break;
        case 'MasterCard':
          icon = <FaCcMastercard size={16} color={'#EB001B'}/>;
          break;
        case 'American Express':
          icon = <FaCcAmex size={16} color={'#1D8ECE'}/>;
          break;
        default:
          icon = <FiCreditCard size={16} color={'#000000'}/>;
      }
    } else {
      icon = <FiCreditCard size={16} color={'#000000'} style={{ marginBottom: -3 }}/>;
    }
    return(
      <div className="payment-method-container" style={this.state.confirmed || this.state.loading ? { backgroundColor: '#e1e1e1' } : null} onClick={() => this.handlePaymentMethodsClick()}>
        <div style={{display: 'flex' }}>
            <div>
              {icon}
            </div>
            <div style={{ marginLeft: 10 }}>
              {this.state.defaultPaymentMethod ? '•••• ' + this.state.defaultPaymentMethod.last4 : 'Select payment method...'}
            </div>
        </div>
        <div style={{display: 'flex' }}>
            <FiChevronRight color={'#707070'} size={16} style={{ marginBottom: -3 }}/>
        </div>
      </div>
    );
  }

  togglePaymentMethodsModal() {
      this.setState({ paymentMethodsModalVisible: !this.state.paymentMethodsModalVisible });
  }

  async submitPaymentMethodChange(paymentMethodObj) {
    try {
      if(paymentMethodObj.id !== this.state.defaultPaymentMethod.id) {
        this.setState({ loading: true, paymentMethodsModalVisible: false });
        await remoteApi.setDefaultPaymentMethod({ payment_method_id: paymentMethodObj.id });
        this.setState({ defaultPaymentMethod: paymentMethodObj, loading: false });
      } else {
        this.setState({ paymentMethodsModalVisible: false });
      }
    } catch(e) {
      this.setState({ loading: false, paymentMethodsModalVisible: false });
    }
  }

  async submitNewPaymentMethod(token) {
    try {
      this.setState({ loading: true, paymentMethodsModalVisible: false });
      await remoteApi.addPaymentMethod({ token });
      await this.getPaymentMethods();
      this.setState({ loading: false });
    } catch(e) {
      if(e.response & e.response.data && e.response.data.message) {
        alert(`Oops. We were unable to add this payment method. ${e.response.data.message}`);
      } else {
        alert('Oops. We were unable to add this payment method.');
      }
      this.setState({ loading: false, paymentMethodsModalVisible: false });
    }
  }

  render() {
    let total = 0;
    let total_poa = false;
    let deposit = 0;
    let businessLocationObj = this.props.business_location_data.find(x => x.business_location_id === this.props.business_location_id);
    return (
      <div
        className="left user-left-mod"
        style={{
          width: '100%',
          backgroundImage: `url(${mediaUrl + this.props.business_img})`
        }}
      >
        <div style={{ position: 'absolute', width: '100%' }}>
          <div className="page-title" style={{backgroundColor: 'rgba(0,0,0,0)'}}>
            {!this.state.confirmed ?
              <button
                className="back-button"
                style={{
                  backgroundColor: 'rgba(0,0,0,0)',
                  color: '#ffffff'
                }}
                onClick={() => { if(!this.state.loading){ this.props.previousPage() } }}
              >
                <FiChevronLeft size={'20px'} />
              </button>
            : null}
            <Button size="sm" disabled={this.state.loading} onClick={() => this.props.accountPage()}>
              Account
            </Button>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <div style={{ flex: 1 }}>
            <div className="user-container" style={{marginBottom: 0, marginTop: 20}}>
              <div className="user-avatar-container">
                <div className="user-avatar">
                  {this.props.user ? this.props.user.user_firstname.charAt(0) + this.props.user.user_lastname.charAt(0) : null}
                </div>
              </div>
              <div className="user-header">
                {this.props.user ? this.props.user.user_firstname + ' ' + this.props.user.user_lastname : null}
              </div>
              <div className="user-body-item">
                <div className="cart-title" style={{ fontWeight: '400' }}>New Booking</div>
                {businessLocationObj ? <div style={{ fontSize: 13, marginTop: 3 }}><FiMapPin size={10} style={{ marginRight: 5 }}/>{businessLocationObj.business_location_name}</div> : null}
                <div className="booking-datetime">
                  On {this.props.date.format('ddd Do MMM YYYY')}{this.props.time ? ' at ' + this.props.time : null}
                </div>
                <div className="service-line"/>
                {this.props.booking_data.map((booking_service_obj, booking_service_index) => {
                  let service_detail_obj = this.props.service_detail_data.find(x => x.service_business_detail_id == booking_service_obj.service_business_detail_id);
                  let service_obj = this.props.service_data.find(x => x.service_id == service_detail_obj.service_business_id);
                  let staff_obj = this.props.staff_data.find(x => x.id === booking_service_obj.staff_id);
                  if(!service_detail_obj || !service_obj) {
                    return null;
                  }
                  // Add to total
                  if(service_detail_obj.service_business_detail_poa === 1) {
                    total_poa = true;
                  } else {
                    total += service_detail_obj.service_business_detail_price;
                  }
                  // Add to deposit
                  if(this.props.business_stripe_account === true && service_detail_obj.service_business_detail_deposit_required === 1) {
                    deposit += service_detail_obj.service_business_detail_deposit_amount;
                  }
                  if(this.props.business_stripe_account === true) {
                    if(this.state.deposit !== deposit) {
                      this.setState({ deposit });
                    }
                  } else {
                    if(this.state.deposit !== 0) {
                      this.setState({ deposit: 0 });
                    }
                  }
                  return (
                    <CartService
                      key={booking_service_obj.service_business_detail_id}
                      line={booking_service_obj}
                      service={service_obj}
                      service_detail={service_detail_obj}
                      staff={staff_obj}
                      date={this.props.date}
                      currency={this.props.business_currency}
                      onRemove={this.props.removeService}
                      deleteEnabled={!this.state.loading && !this.state.confirmed}
                    />
                  );
                })}
                <div>
                  {Cart(this.props.booking_data.length, total, total_poa, this.props.business_currency, deposit)}
                  {deposit === 0 && ((this.props.business_cancellation_fee_enabled && this.props.business_cancellation_fee_enabled === 1) || (this.props.business_no_show_fee_enabled && this.props.business_no_show_fee_enabled === 1)) ?
                    <div className="booking-deposit-note">
                      No deposit is necessary but a payment method is required to be on file to make this booking.
                    </div>
                  : null}
                  {deposit > 0 || ((this.props.business_cancellation_fee_enabled && this.props.business_cancellation_fee_enabled === 1) || (this.props.business_no_show_fee_enabled && this.props.business_no_show_fee_enabled === 1)) ?
                    this.renderSelectedCard()
                  : null}
                </div>
                <Button
                  fullWidth
                  disabled={this.state.loading}
                  className="book-button"
                  color={this.state.confirmed ? "success" : "black"}
                  onClick={() => { this.submitBooking() }}
                  style={{ margin: 0 }}
                >
                  {this.state.loading ? <CircularProgress size={20} color={'secondary'}/> : null}
                  {this.state.confirmed ? <FiCheck size={20}/> : null}
                  <span style={{marginLeft: 10}}>{this.state.loading ? null : this.state.confirmed ? 'Booked' : 'Book' }</span>
                </Button>
                {this.state.bookError ?
                  <div className="sign-in-error-container">
                    <div className="sign-in-error-label" style={{fontSize: 12}}>
                      {this.state.bookErrorMessage}
                    </div>
                  </div>
                : null}
              </div>
            </div>
            {this.state.confirmed ?
              <div className="user-container-footer">
                <div className="user-container-footer-span">
                  <div style={{textAlign: 'center', fontFamily: 'Poppins', fontWeight: '500'}}>
                    <p style={{fontSize: 12, marginBottom: 5}}>Your booking with {this.props.business_name} is now confirmed! We'll send you an email confirmation shortly.</p>
                  </div>
                  <div>
                    <FaBarcode size={40}/><FaBarcode size={40}/>
                  </div>
                </div>
              </div>
            : null}
          </div>
        </div>
        <PaymentMethodsModal
          visible={this.state.paymentMethodsModalVisible}
          toggleVisible={this.togglePaymentMethodsModal}
          data={this.state.paymentMethods}
          defaultPaymentMethod={this.state.defaultPaymentMethod}
          elements={this.props.elements}
          stripe={this.props.stripe}
          submitDefaultPaymentMethod={this.submitPaymentMethodChange}
          submitNewPaymentMethod={this.submitNewPaymentMethod}
          allowDelete={false}
        />
      </div>
    );
  }
};

export default (WizardFormLandingPage5);