import React from 'react';
import './Form.css';
import sanitizeHtml from 'sanitize-html';
import Button from "./components/materialComponents/CustomButtons/Button.js";
import { FiChevronLeft } from 'react-icons/fi';

const SanitizeHTML = (html) => (
    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(html)}} />
);

const WizardFormLandingPageTerms = props => {
  const { nextPage, business_settings, booking_terms, previousPage } = props;
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className="landing-title" style={{backgroundColor: business_settings?.service_header_background, color: business_settings?.service_header_title}}>
            <div onClick={previousPage} style={{ position: 'absolute', paddingLeft: 10, paddingTop: 10, left: 0, display: 'inline-block', cursor: 'pointer', marginRight: 10 }}>
                <FiChevronLeft size={30}/>
            </div>
            <div>Terms & Conditions</div>
        </div>
        <div className="landing-terms-container">
            {SanitizeHTML(booking_terms)}
        </div>
        <div className="landing-footer" style={{backgroundColor: business_settings?.landing_footer_background}}>
            <div className="landing-footer-title">
                <div className="landing-footer-button-group">
                    <Button onClick={(nextPage)}>Accept</Button>
                </div>
            </div>
        </div>
    </div>
    );
};

export default 
// reduxForm({
//   form: 'wizard', //                 <------ same form name
//   destroyOnUnmount: false, //        <------ preserve form data
//   forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
// })
(WizardFormLandingPageTerms);