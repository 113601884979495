import React, { Component } from 'react';
import CustomServiceAccordion from "./materialComponents/CustomAccordion/ServiceAccordion.js";
import { FiPlus, FiCheck } from 'react-icons/fi';
// React tooltip
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
// Components
import { StaffPicker } from "./StaffPicker";
// Constants
import { mediaUrl } from './../utils/constants';

class BusinessServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded_business_service_id: null,
            staffPickerServiceBusinessDetailId: null,
            staffPickerData: null,
            staffPickerVisible: false
        };
        this.handleAccordianToggle = this.handleAccordianToggle.bind(this);
        this.toggleStaffPickerModal = this.toggleStaffPickerModal.bind(this);
        this.submitStaffPickerSelection = this.submitStaffPickerSelection.bind(this);
    }

    componentDidMount(){
        if(this.props.linked_service_id) {
            this.setState({ expanded_business_service_id: this.props.linked_service_id });
        }
    }

    handleAccordianToggle(service_business_id) {
        if(service_business_id === this.state.expanded_business_service_id) {
            this.setState({ expanded_business_service_id: null });
        } else {
            this.setState({ expanded_business_service_id: service_business_id });
        }
    }

    handleServiceToggle(service_business_detail_id) {
        if(this.props.booking_data.find(x => x.service_business_detail_id === service_business_detail_id)) {
            this.props.removeService(service_business_detail_id);
        } else {
            let staff_id = null;
            let location_staff_ids = this.props.business_location_staff_map_data.filter(x => x.business_location_id === this.props.business_location_id).map(a => a.staff_id);
            let service_staff_mappings = this.props.service_staff_map_data.filter(x => x.service_business_detail_id === service_business_detail_id && location_staff_ids.includes(x.staff_id));
            if(service_staff_mappings.length > 1) {
                let service_staff_ids = service_staff_mappings.map(a => a.staff_id);
                let service_staff_data = this.props.staff_data.filter(x => service_staff_ids.includes(x.id));
                this.setState({ staffPickerVisible: true, staffPickerData: service_staff_data, staffPickerServiceBusinessDetailId: service_business_detail_id });
                return;
            } else if(service_staff_mappings.length === 1) {
                staff_id = service_staff_mappings[0].staff_id;
            } else {
                // No staff assigned to this service
                return;
            }
            this.props.addService(service_business_detail_id, staff_id);
        }
    }

    toggleStaffPickerModal() {
        this.setState({ staffPickerVisible: !this.state.staffPickerVisible });
    }

    submitStaffPickerSelection(staff_id) {
        this.props.addService(this.state.staffPickerServiceBusinessDetailId, staff_id);
        this.setState({ staffPickerVisible: false });
    }

    renderStaffPickerModal() {
        return (
            <StaffPicker
                visible={this.state.staffPickerVisible}
                toggleVisible={this.toggleStaffPickerModal}
                data={this.state.staffPickerData}
                business_settings={this.props.business_settings}
                submit={this.submitStaffPickerSelection}
            />
        );
    }

    renderCategoryServices(service_category_obj, service_location_map_data) {
        let category_service_data = this.props.service_data.filter(x =>
            x.service_business_category_id == service_category_obj.service_business_category_id &&
            service_location_map_data.find(y => y.service_business_id === x.service_id)
        );
        return (
            <div>
                {category_service_data.map((service_obj,) =>
                    <div key={`service${service_obj.service_id}`}>
                        <CustomServiceAccordion
                            serviceBusinessId={service_obj.service_id}
                            title={service_obj.service_name ? service_obj.service_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null}
                            description={service_obj.service_description ? service_obj.service_description.replace("%comma%", ",").replace("%apostrophe%", "'") : null}
                            expanded={this.state.expanded_business_service_id === service_obj.service_id}
                            toggleCollapse={this.handleAccordianToggle}
                            businessSettings={this.props.business_settings}
                        >
                            {this.props.service_detail_data && this.props.service_detail_data.filter(x => x.service_business_id == service_obj.service_id).map((service_detail_obj, service_detail_index) => {
                                let service_staff_ids = this.props.service_staff_map_data.filter(x => x.service_business_detail_id === service_detail_obj.service_business_detail_id).map(a => a.staff_id);
                                let location_staff_ids = this.props.business_location_staff_map_data.filter(x => x.business_location_id === this.props.business_location_id).map(a => a.staff_id);
                                let service_staff_data = this.props.staff_data.filter(x => service_staff_ids.includes(x.id) && location_staff_ids.includes(x.id));
                                return (
                                    <div
                                        key={'serviceDetail' + service_detail_obj.service_business_detail_id}
                                        className="service-detail-container"
                                        onClick={() => this.handleServiceToggle(service_detail_obj.service_business_detail_id)}
                                    >
                                        <div className="service-detail-info">
                                            <div
                                                className="service-detail-title"
                                                style={this.props.business_settings?.service_tier_title ? {color: this.props.business_settings?.service_tier_title} : null}
                                            >
                                                {service_detail_obj.service_business_detail_name ? service_detail_obj.service_business_detail_name.replace("%comma%", ",").replace("%apostrophe%", "'") : null}
                                            </div>
                                            <div
                                                className="service-detail-right"
                                                style={this.props.business_settings?.service_tier_title ? {color: this.props.business_settings?.service_tier_title} : null}
                                            >
                                                {service_detail_obj.service_business_detail_poa === 1 ? (
                                                    'POA'
                                                ) : (
                                                    this.props.currency + Number(service_detail_obj.service_business_detail_price / 100).toFixed(2)
                                                )}
                                                {this.props.booking_data.find(x => x.service_business_detail_id === service_detail_obj.service_business_detail_id) ? (
                                                    <FiCheck size={'1.3em'} color={'#4caf50'} style={{marginLeft: '15px'}} />
                                                ) : (
                                                    <FiPlus size={'1.3em'} style={{marginLeft: '15px'}} />
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="service-detail-description"
                                            style={this.props.business_settings?.service_tier_description ? {color: this.props.business_settings?.service_tier_description} : null}
                                        >
                                            <span style={{fontWeight: 'bold'}}>
                                                {service_detail_obj.service_business_detail_duration_a} mins
                                            </span>
                                            {service_detail_obj.service_business_detail_description ? ' | ' : ' '}
                                            {service_detail_obj.service_business_detail_description ? service_detail_obj.service_business_detail_description.replace("%comma%", ",").replace("%apostrophe%", "'") : null}
                                        </div>
                                        <div className="service-detail-description">
                                            {service_staff_data.map((staff_obj, staff_index) => 
                                                <img
                                                    key={'service_business_detail_staff' + staff_obj.id}
                                                    src={mediaUrl + staff_obj.staff_img}
                                                    data-tooltip-content={staff_obj.firstname + ' ' + staff_obj.lastname}
                                                    data-tooltip-id="staff-img-tooltip"
                                                    className="service-detail-title-img"
                                                    height="26"
                                                    width="26"
                                                />
                                            )}
                                            {service_staff_data.length === 0 ? <span style={{ color: '#f44336' }}>No staff available</span> : null}
                                        </div>
                                    </div>
                                )
                            })}
                            <Tooltip id="staff-img-tooltip"/>
                        </CustomServiceAccordion>
                        <div className="service-line"/>
                    </div>
                )}
            </div>
        );
    }

    render() {
        let service_location_map_data = this.props.service_location_map_data.filter(x => x.business_location_id === this.props.business_location_id);
        return (
            <div>
                {this.props.service_category_data && this.props.service_category_data.map(service_category_obj =>
                    <div
                        key={`serviceCategory${service_category_obj.service_business_category_id}`}
                        className={this.props.service_category_data.length === 1 ? 'services-container' : null}
                        style={{backgroundColor: this.props.business_settings?.service_background}}
                    >
                        {this.props.service_category_data.length > 1 ?
                            <div
                                className="service-header"
                                style={{backgroundColor: this.props.business_settings?.service_category_background, color: this.props.business_settings?.service_category_title}}
                            >
                                {service_category_obj.service_business_category}
                            </div>
                        : null}    
                        <div className="service-line"/>
                        {this.renderCategoryServices(service_category_obj, service_location_map_data)}
                    </div>
                )}
                {this.renderStaffPickerModal()}
            </div>
        );
    }
}

export default BusinessServices;